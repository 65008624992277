
 .flip-2-hor-top-1 {
    -webkit-animation: flip-2-hor-top-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both, fadeIn 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: flip-2-hor-top-1 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both, fadeIn 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-2-hor-top-1 {
    0% {
        -webkit-transform: translateY(-100%) rotateX(-90deg);
        transform: translateY(-100%) rotateX(-90deg);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotateX(0);
        transform: translateY(0) rotateX(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
}

@keyframes flip-2-hor-top-1 {
    0% {
        transform: translateY(-100%) rotateX(-90deg);
        transform-origin: 50% 100%;
        opacity: 0;
    }
    100% {
        transform: translateY(0) rotateX(0);
        transform-origin: 50% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}



