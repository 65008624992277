@tailwind base;
@tailwind components;
@tailwind utilities;



.hidescrollbar::-webkit-scrollbar {
    display: none !important;
    
}

.hidescrollbar {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}
  


.link-div {
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .link-div:hover {
    background-color: #333;
    transform: translateY(-5px);
   
  }
  
  .link-div:active {
    transform: translateY(0);
  }

  .logo {
    
    margin: 0 auto;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .logo:hover {
    transform:rotate3d(10, 50, 10, 30deg);
    box-shadow: #333;
    border: 11px solid #333;
    
    
  }

  .sidebar-div {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  .button {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
  }

  .button:hover {
    background-color: #fff;
    color:#7a6656;
    border: 1px solid #333;
   transform: scale3d(1.1, 1.2, 1.6);
  }
  .addCamIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
  }

  .addCamIcon {
    
    color: #fff;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
  }

    .addCamIcon:hover {
        
        color:#b4e761;
        
      
    }

    .stepperNavi {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .fade-in {
        animation: fadeIn 0.5s;
      }
      
      .fade-out {
        animation: fadeOut 0.5s;
      }
      
      @keyframes fadeIn {
        from {
          
          opacity: 0;
        }
        to {
          
            opacity: 1;
        }
      }
      
      @keyframes fadeOut {
        from {
          
            opacity: 1;

        }
        to {
            
            opacity: 0;
        }
      }
      

    
      #content {
        transition: opacity 0.5s ease-in-out;
      }
      
      #content.fade-in {
        opacity: 1;
      }

      @keyframes slideIn {
        0% {
          opacity: 0;
          transform: translateX(-50%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      
      .animate-slideIn {
        animation: slideIn 1s ease-out forwards;
      }
      .animate-rotate {
        animation: rotate 1s ease-in-out;
      }