/* Overlay-elementille jätetään vain opacity-siirtymä */
.ReactModal__Overlay {
    opacity: 0;
    background-color: transparent   ;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    background-color: white;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    background-color: transparent;
}

/* Skaalaus tehdään contentBox-elementille */
.contentBox {
    transform: scale(0); /* Asetetaan aluksi nollaan */
   
    transition: transform 500ms ease-in-out; /* Skaalautumisen siirtymä */
}

.ReactModal__Overlay--after-open .contentBox {
    transform: scale(1); /* Skaalautuu täyteen kokoon avattaessa */
   
}

.ReactModal__Overlay--before-close .contentBox {
    transform: scale(0); /* Skaalautuu takaisin nollaan suljettaessa */
    
}
.animated-text-container {
    display: inline-block;
    flex-direction: row;
    padding-right: 10px;
    overflow: hidden;
    white-space: pre;
  }
  
  .animated-text {
    display: inline-block;
    opacity: 0;
    transform: translateX(-10px);
    animation: textReveal 0.1s ease forwards;
    animation-delay: calc(0.1s * var(--char-index));
  }
  
  /* Animaatio tekstin ilmestymiseen */
  @keyframes textReveal {
    0% {
      opacity: 0;
      transform: translateX(-10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }